<template>
  <div>
    <van-popup v-model:show="show" position="bottom" @close="confirm">
      <van-datetime-picker
        v-model="date"
        :formatter="formatter"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        @confirm="confirm"
        @cancel="cancel"
      />
    </van-popup>
  </div>
</template>

<script>
import { DatetimePicker, Popup } from 'vant'
export default {
  name: 'DatetimePicker',
  components: {
    [DatetimePicker.name]: DatetimePicker,
    [Popup.name]: Popup,
  },
  props: {
    currentDate: undefined,
  },
  data() {
    return {
      minDate: new Date(2022, 0, 1),
      show: true,
      date: undefined,
    }
  },
  mounted() {
    if (this.currentDate) {
      this.data = this.currentDate
    }
  },
  methods: {
    confirm(res) {
      this.$emit('confirm', res)
    },
    cancel() {
      this.$emit('confirm', undefined)
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`
      }
      if (type === 'month') {
        return `${val}月`
      }
      if (type === 'day') {
        return `${val}日`
      }
    },
  },
}
</script>
