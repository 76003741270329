<template>
  <NavBar :title="'添加活动'"></NavBar>
  <div class="form-item">
    <div class="item">
      <p>活动名称</p>
      <input type="text" v-model="fromData.title" />
    </div>
    <div class="item">
      <p>优惠券名称</p>
      <input type="text" v-model="fromData.coupon_title" />
    </div>
    <div class="item">
      <p>优惠券描述</p>
      <input type="text" v-model="fromData.coupon_desc" />
    </div>
    <div class="item">
      <p>优惠券金额</p>
      <input type="text" v-model="fromData.coupon_money" />
    </div>
    <div class="item">
      <p>满减金额</p>
      <input type="text" v-model="fromData.coupon_full_money" />
    </div>
    <div class="item">
      <p>开始日期</p>
      <p class="prev-item" @click="showStartTimeEdit = !showStartTimeEdit">
        {{ startTime }}
      </p>
      <DatetimePicker
        v-if="showStartTimeEdit"
        @confirm="confirmStartTime"
      ></DatetimePicker>
    </div>
    <div class="item">
      <p>结束日期</p>
      <p class="prev-item" @click="showEndTimeEdit = !showEndTimeEdit">
        {{ endTime }}
      </p>
      <DatetimePicker
        v-if="showEndTimeEdit"
        @confirm="confirmEndTime"
      ></DatetimePicker>
    </div>
    <div class="item">
      <p>每人限制核销数量</p>
      <input type="text" v-model="fromData.user_num" />
    </div>
    <div class="item">
      <p>总数量</p>
      <input type="text" v-model="fromData.num" />
    </div>
    <div class="item">
      <p>门店地址</p>
      <Address v-if="showAddressEdit" @confirm="confirmAddress"></Address>
      <p class="prev-item" @click="showAddressEdit = true">
        {{ address }}
      </p>
    </div>
    <div class="item">
      <p>门店详细地址</p>
      <input type="text" v-model="fromData.address" />
    </div>
    <div class="item">
      <button @click="submit">提交</button>
    </div>
  </div>
</template>
<script>
import { Popup } from 'vant'
import Address from '@/components/Address'
import DatetimePicker from '@/components/DatetimePicker'
import moment from 'moment'
import NavBar from '@/components/NavBar'
export default {
  name: 'activityAdd',
  components: {
    [Popup.name]: Popup,
    [Address.name]: Address,
    [DatetimePicker.name]: DatetimePicker,
    NavBar,
  },
  created() {
    moment.locale('zh-cn')
  },
  data() {
    return {
      fromData: {},
      address: '',
      startTime: '',
      endTime: '',
      showAddressEdit: false,
      showEndTimeEdit: false,
      showStartTimeEdit: false,
    }
  },
  methods: {
    closeHandler() {
      this.$emit('onLoad', true)
    },
    confirmAddress(res) {
      this.showAddressEdit = false
      if (res && res !== null && res.length === 3) {
        this.fromData.province = res[0].code
        this.fromData.city = res[1].code
        this.fromData.county = res[2].code
        this.address = res[0].name + '-' + res[1].name + '-' + res[2].name
      }
    },
    confirmStartTime(res) {
      this.showStartTimeEdit = false
      if (res) {
        this.startTime = moment(res).format('LL')
        this.fromData.start_date = res
      } else {
        this.$notify('时间选择错误')
      }
    },
    confirmEndTime(res) {
      this.showEndTimeEdit = false
      if (res) {
        this.endTime = moment(res).format('LL')
        this.fromData.end_date = res
      } else {
        this.$notify('时间选择错误')
      }
    },
    submit() {
      this.$http({
        url: '/merchant/merchantActivityCreate',
        method: 'post',
        data: this.$http.adornData(this.fromData),
      }).then((res) => {
        this.$notify({ type: 'success', message: res.msg })
        this.$router.push('/')
      })
    },
  },
}
</script>
<style lang="less" scoped>
.form-item {
  padding: 32px;
  .item {
    margin: 10px 0;
    .prev-item {
      height: 34px;
      padding: 0;
      border-radius: 4px 4px 4px 4px;
      color: #000;
      border: 1px solid rgba(0, 0, 0, 0.26);
      line-height: 34px;
    }
    p {
      margin: 0;
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      line-height: 24px;
    }
    input {
      width: 100%;
      height: 34px;
      padding: 0;
      border-radius: 4px 4px 4px 4px;
      color: #000;
      border: 1px solid rgba(0, 0, 0, 0.26);
    }
    button {
      width: 100%;
      height: 44px;
      background: linear-gradient(235deg, #f56f4d 0%, #f35450 100%);
      border-radius: 8px 8px 8px 8px;
      border: none;
      color: #fff;
    }
  }
}
</style>
